<template>
  <div class="vipMain">
    <div class="backHome">
      <span class="backIco" @click="back('prev')"></span>
      <span class="backHomeIco" @click="back('main')"></span>
      {{ currentLang.title }}
    </div>
    <div class="setting">
      <div>
        <div class="box flex flex__justify flex__middle">
          <div>{{ currentLang.myAvatar }}</div>
          <div>
            <div class="flex flex__middle" @click.stop="uploadHeadImg">
              <van-image round width="3rem" height="3rem" :src="headimg" />
              <img class="icon28" src="../../assets/img/iconfonti.png" />
            </div>
          </div>
          <!--   <div class="setting_right" @click.stop="uploadHeadImg">
            <div class="caption">更改头像</div>
          </div> -->
          <input type="file" accept="image/*" @change="handleFile" class="hiddenInput" style="display: none" />
        </div>

        <div class="box flex flex__justify flex__middle" @click="showMe()">
          <div>{{ currentLang.myProfile }}</div>
          <div>
            <img class="icon28" src="../../assets/img/iconfonti.png" />
          </div>
        </div>
        <!--        <div class="box flex flex__justify flex__middle">
          <div>个性签名</div>
          <div>
            <img class="icon28" src="../../assets/img/iconfonti.png" />
          </div>
        </div> -->
        <div class="box flex flex__justify flex__middle" @click="languageActive = true" style="display: none">
          <div>{{ currentLang.myLanguage }}</div>
          <div>
            <div class="flex flex__middle">
              <div>{{ languageTitle }}</div>
              <img class="icon28" src="../../assets/img/iconfonti.png" />
            </div>
          </div>
        </div>
        <!-- 更改密码 -->
<!--        <div class="box flex flex__justify flex__middle" @click="changePwd()">
          <div>{{ currentLang.changePassword }}</div>
          <div>
            <img class="icon28" src="../../assets/img/iconfonti.png" />
          </div>
        </div> -->

        <!-- 我的银行信息 -->
        <div class="box flex flex__justify flex__middle" @click="toBank()">
          <div>我的银行卡</div>
          <div>
            <img class="icon28" src="../../assets/img/iconfonti.png" />
          </div>
        </div>

        <!-- 不要展示我 -->
        <!--        <div class="box flex flex__justify flex__middle">
          <div>已找到意中人(不再展示)</div>
          <div>
            <img class="icon28" src="../../assets/img/iconfonti.png" />
          </div>
        </div> -->
      </div>

      <!--    <div class="btn" @click="logout()">{{ currentLang.logout }}</div> -->

    </div>

    <van-popup v-model="languageActive" position="bottom" close-on-click-overlay>
      <div class="languageBox">
        <p v-for="(yuyan, i) in languageJSON" @click="checkLanguage(yuyan, i)"
          :class="[yuyan.language_id == language_id ? 'yuyanActive' : '']">
          {{ yuyan.text }}
        </p>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from "js-cookie";
  import {
    getQueryVariable
  } from "@/utils/request";
  import {
    Popup,
    Toast
  } from "vant";
  import wx from 'weixin-js-sdk';
  export default {
    name: "",
    props: {},
    data() {
      return {
        headimg: "",
        languageTitle: "", //界面显示当前选择的语言
        languageActive: false,
        language_id: 1, //语言id， 1，英文 2，简体中文 3，繁体中文
        languageJSON: [{
            language_id: 1,
            text: "English",
          },
          {
            language_id: 2,
            text: "简体中文",
          },
          {
            language_id: 3,
            text: "繁体中文",
          },
        ],
        languageLib: {
          lang_1: {
            title: "setting",
            myAvatar: "My Avatar",
            myProfile: "My Profile",
            myLanguage: "My Language",
            changePassword: "Change Password",
            logout: "Logout",
            language: "English",
            imgMessage1: "Only JPG/JPEG/PNG format permitted! ",
            imgMessage2: "image size can't be more than 1MB!",
          },
          lang_2: {
            title: "个人设置",
            myAvatar: "我的头像",
            myProfile: "资料设置(性别,出生地)",
            myLanguage: "语言",
            changePassword: "更换密码",
            logout: "退出",
            language: "简体中文",
            imgMessage1: "上传图片只能是 JPG/JPEG/PNG 格式!",
            imgMessage2: "上传图片大小不能超过 1MB!",
          },
          lang_3: {
            title: "個人設置",
            myAvatar: "我的頭像",
            myProfile: "資料設置",
            myLanguage: "語言",
            changePassword: "更換密碼",
            logout: "登出",
            language: "繁體中文",
            imgMessage1: "上传图片只能是 JPG/JPEG/PNG 格式",
            imgMessage2: "上传图片大小不能超过 1MB! ",
          },
        },
        currentLang: {},
      };
    },
    components: {
      [Popup.name]: Popup,
    },
    beforeMount() {
      const language_id = Cookies.get("language_id") || 2; //2022-3-9 按照客户要求默认改为简体字 //客户现在要求默认是繁体
      this.currentLang = this.languageLib["lang_2"];
    },
    mounted() {
      var url = location.href;
      //语言id， 1，英文 2，简体中文 3，繁体中文
      this.language_id = Cookies.get("language_id") || 1;
      console.log("=============>" + this.language_id);
      this.languageTitle = this.languageJSON[this.language_id - 1].text;
      this.getUserInfo();
      this.getWxConfig(); //加载js
    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });

        } else {
          Toast(result.msg);
        }
      },


      checkLanguage(yuyan, i) {
        this.language_id = yuyan.language_id;
        this.currentLang = this.languageLib["lang_" + this.language_id];
        Cookies.set("language_id", this.language_id);
        this.languageActive = false;
        this.languageTitle = this.languageJSON[this.language_id - 1].text;
      },
      back(where) {
        //顶部后退和返回按钮
        if (where == "main") {
          this.$router.push({
            name: "main",
          });
        }
        if (where == "prev") {
          this.$router.back();
        }
      },
      changePwd() {
        //修改密码
        this.$router.push({
          path: "/myPwdChange",
          query: {},
        });
      },
      showMe() {
        //个人资料
        this.$router.push({
          path: "/myData",
          query: {},
        });
      },
      toBank() {
        //去银行
        this.$router.push({
          path: "/myBank",
          query: {},
        });
      },
      // 打开图片上传
      uploadHeadImg: function() {
        this.$el.querySelector(".hiddenInput").click();
      },
      // 将头像显示
      handleFile: function(e) {
        let $target = e.target || e.srcElement;
        let file = $target.files[0];
        var reader = new FileReader();
        reader.onload = (data) => {
          let res = data.target || data.srcElement;
          this.headimg = res.result;
        };

        const isImg = file.type === "image/jpeg" || file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 10;

        if (!isImg) {
          Toast(this.currentLang.imgMessage1);
          return false;
        }
        if (!isLt2M) {
          Toast(this.currentLang.imgMessage2 + file.size);
          return false;
        }

        reader.readAsDataURL(file);

        //直接调用上传接口，将图片传给后台
        //要考虑文件加载时间,先做了一个延时
        setTimeout(() => {
          //需要延迟的代码 :3秒后延迟跳转到首页，可以加提示什么的
          this.uploadImg(file);
          //延迟时间：秒
        }, 500);
      },
      //通过接口传图片
      async uploadImg(file) {
        var _this = this;
        let file1 = this.headimg; //获取文件数据
        var arr = [];
        arr.push({
          imgurl: file1,
        });
        console.log(arr);
        var imglist = JSON.stringify(arr);
        var uri = "";
        uri = this.$global.domain + "/tools/mallsubmit.ashx";
        var param = {
          action: "saveImglist",
          user: Cookies.get("kh"),
          type: 2, //1 传照片  2传头像
          imglist: imglist,
          sitenum: "18777777777",
          token: Cookies.get("token"), //验证用户合法性
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          //
          Toast("頭像更改成功");
          //location.reload(); //先刷新吧
        } else {
          Toast(result.msg);
        }
      },
      async getUserInfo() {
        //获取用户信息，如果没登录，则跳转到登录
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "getUserInfo",
          user: Cookies.get("kh"),
          token: Cookies.get("token"),
          sitenum: "18777777777",
        };
        var result = await RequestApi.post(uri, param);
        console.log(result);
        if (result.status == "1") {
          that.shopList = result.data;
          that.userName = result.name;
          that.userTel = result.tel;
          that.userBalance = result.kje; //会员的账户余额
          //that.userAge = result.userAge;  //会员的岁数
          that.userGrade = result.fklx;
          that.userSex = result.sex; //会员的岁数
          that.expiringDate = result.sxrq; //会员的岁数
          //that.headimg=require(result.avatar);
          that.headimg = result.avatar;
        } else {
          // console.log("==================>用户没登录")
          this.$router.push("login");
        }
      },
      async logout() {
        //退出登录
        //接口去服务器通知下线
        var that = this;
        var uri = "";
        uri = this.$global.domain + "/tools/MallSelect.ashx";
        var param = {
          action: "quit2021",
          user: Cookies.get("kh"),
          token: Cookies.get("token"),
          sitenum: "18777777777",
        };
        var result = await RequestApi.post(uri, param);
        //console.log(result);
        if (result.status == "1") {} else {}
        let openid = Cookies.get("openid");
        Cookies.set("kh", "");
        Cookies.set("openid", "");
        this.$router.push("login");
      },
    },
    computed: {},
    watch: {},
  };
</script>

<style scoped lang="less">
  .yuyanActive {
    color: rgb(56, 140, 209) !important;
    font-weight: 600;
  }

  .languageBox {
    height: 100%;
    width: 100%;
  }

  .languageBox p {
    width: 80%;
    height: 30px;
    line-height: 30px;
    display: block;
    position: relative;
    margin: 0 auto;
    color: #666;
    text-align: center;
    font-size: 14px;
    border-bottom: 1px #d4d4d4 solid;
  }

  .languageBox p:last-child {
    border-bottom: 1px transparent solid;
  }

  .setting {
    margin-left: 15px;
    margin-right: 15px;

    .box {
      width: 100%;
      height: 62px;
      border-bottom: 1px solid #999;
      color: #999;
    }

    .btn {
      width: 92%;
      height: 52px;
      background: #fd4c54;
      border-radius: 30px;
      text-align: center;
      line-height: 52px;
      color: #fff;
      font-size: 18px;
      position: fixed;
      bottom: 10px;
    }
  }

  .vipMain {
    min-height: 300px;
    padding-bottom: 120px;
    padding-top: 50px;
  }

  .backHome {
    height: 38px;
    width: 100%;
    line-height: 38px;
    position: fixed;
    background-color: white;
    box-shadow: 2px 2px 6px #bfb8b8;
    display: inline-block;
    top: 0px;
    text-align: center;
    font-size: 16px;
    color: #222;
    z-index: 999;

    span {
      position: absolute;
      // border: 1px red dashed;
    }

    .backIco {
      left: 15px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/back2.png");
    }

    .backHomeIco {
      left: 43px;
      top: 10px;
      width: 18px;
      height: 18px;
      display: inline-block;
      background-position: center;
      background-size: 16px 16px;
      background-repeat: no-repeat;
      background-image: url("../../assets/img/home.png");
    }
  }
</style>
